@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Neuton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spectral:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

:root {
  --primary: #ffffff;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn--primary {
  background-color: #ffffff;
  background-color: var(--primary);
  color: #242424;
  border: 1px solid #ffffff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #ffffff;
  padding: 8px 20px;
  border: 1px solid #ffffff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn-medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--mobile {
  text-align: center;
  border-radius: 4px;
  width: 40%;
  text-decoration: none;
  font-size: 24px;
  background-color: transparent;
  color: #ffffff;
  padding: 14px 20px;
  border: 1px solid #ffffff;
  transition: all 0.3s ease-out;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: #ffffff;
  color: #242424;
}

.btn--wide:hover {
  color: #ffffff;
  background-color: rgb(255, 115, 0);
  transition: all 0.2s ease-out;
}

.blue {
  background-color: blue;
  color: #ffffff;
  border: none;
}

.red {
  background-color: red;
  color: #ffffff;
  border: none;
}

.primary {
  background-color: #242424;
  color: #ffffff;
  border: none;
}

.primary:hover {
  background-color: #ffffff;
  color: #242424;
  border: none;
}

.green {
  background-color: #25ce4a;
  color: #ffffff;
  border: none;
}

.green:hover {
  background-color: #242424;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}

.btn--large--appStore {
  /* background: add image; */
  padding: 12px 64px;
  font-size: 20px;
  border: none;
}

.btn--large--playStore {
  /* background: url("images/Google.png") no-repeat; */
  padding: 12px 26px;
  font-size: 20px;
  border: none;
}

.navbar {
  background: rgb(24, 161, 5);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1700px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: end;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #3309f0;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1c2237;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #f05209;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
}

/* classNames for all components */
/* .home__hero-section {
  color: black;
  padding: 90px 0;
} */

.darkBg {
  background-color: rgb(48, 48, 48);
}

.orangeBg {
  background: rgb(24, 161, 5);
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1 1;
  max-width: 50%;
  flex-basis: 50%;
}

.dark {
  color: #37291c;
}

.heading {
  margin-bottom: 24px;
  font-family: "Roboto", serif;
  font-size: 50px;
  line-height: 1.1;
  font-weight: 300;
  color: rgb(2, 39, 247);
  text-align: center;
}

/* CSS Styling for Kim_HomePage */

.home__hero-section {
  color: black;
  padding: 90px 0;
}

.home__hero-row {
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.home__hero-text-wrapper {
  max-width: 700px;
  padding-top: 0;
  padding-bottom: 60px;
}

.top-line {
  color: rgb(255, 115, 0);
  font-family: "Roboto Slab", serif;
  font-size: 45px;
  line-height: 80px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 5px;
}

.home__heading {
  margin-bottom: 30px;
  font-family: "Neuton", serif;
  font-size: 50px;
  line-height: 1.1;
  letter-spacing: 0.4rem;
  font-weight: 300;
  color: rgb(2, 39, 247);
  text-align: center;
}

.home__hero-subtitle {
  margin-top: 50px;
  max-width: 730px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  padding-top: 10px;
}

.home__hero-img-wrapper {
  max-width: 555px;
}

.home__hero-img {
  max-width: 95%;
  margin: 0 0 0 100px;
}

/* CSS Styling for Download Button on HomePage */

.download__hero-section {
  color: black;
  padding: 90px 0;
}

.download__hero-row {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0px;
}

.download__hero-text-wrapper {
  max-width: 700px;
  padding-top: 40px;
  padding-bottom: 70px;
}

.download__heading {
  margin-bottom: 24px;
  font-family: "Orbitron", sans-serif;
  font-size: 50px;
  line-height: 1.1;
  letter-spacing: 0.2rem;
  font-weight: 300;
  color: rgb(245, 243, 243);
  text-align: center;
}

.download--button {
  display: flex;
  margin-top: 70px;
  max-width: 700px;
  justify-content: center;
}

.download__hero-imgApp {
  display: flex;
  max-width: 700px;
  justify-content: center;
  align-items: center;
  padding-right: 40px;
}

.download__hero-imgPlay {
  display: flex;
  max-width: 700px;
  justify-content: center;
  padding-left: 40px;
}

/* CSS Styling for BeatBoi component Home Page */
.beatboi__hero-section {
  color: black;
  padding: 90px 0;
}

.beatboi__hero-row {
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.beatboi__hero-img-wrapper {
  max-width: 555px;
  padding: 25px;
}

.beatboi__hero-text-wrapper {
  max-width: 700px;
  padding-top: 0;
  padding-bottom: 60px;
}

.beatboi__heading {
  font-family: "Teko", sans-serif;
  font-size: 80px;
  /* line-height: 1.1; */
  /* letter-spacing: 0.4rem; */
  font-weight: 300;
  color: rgb(247, 112, 2);
  text-align: center;
  padding-right: 80px;
}

.beatboi__hero-subtitle {
  font-family: "Spectral", serif;
  max-width: 550px;
  font-size: 30px;
  line-height: 55px;
  text-align: center;
  padding-top: 5px;
  padding-left: 80px;
  padding-right: 20px;
}

/* CSS for TEAM PAGE */ /* CSS for TEAM PAGE */ /* CSS for TEAM PAGE */ /* CSS for TEAM PAGE */ /* CSS for TEAM PAGE */ /* CSS for TEAM PAGE */ /* CSS for TEAM PAGE */
/* CSS for TEAM PAGE */ /* CSS for TEAM PAGE */ /* CSS for TEAM PAGE */ /* CSS for TEAM PAGE */ /* CSS for TEAM PAGE */ /* CSS for TEAM PAGE */ /* CSS for TEAM PAGE */

.team__hero-section {
  color: black;
  padding: 90px 0;
}

.team__container {
  color: white;
}

.team__container h1 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  padding-bottom: 20px;
}

.team__hero-text-wrapper {
  max-width: 400px;
  padding-top: 0;
  padding-bottom: 60px;
}

.team__heading {
  font-size: 70px;
  color: rgb(255, 115, 0);
  font-weight: 300;
}

.team__profiles {
  display: flex;
  justify-content: space-around;
  margin: 20px 80px;
}

.team_profile {
  flex-basis: 300px;
}

.team__profile .team__profile-img {
  height: 260px;
  width: 260px;
  border-radius: 50%;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  cursor: pointer;
  transition: 400ms;
}

.team__profile-img {
  margin-left: 50px;
  padding: 4px;
  align-items: center;
}

.team__profile:hover .team__profile-img {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}

.team__user-name {
  font-family: "Poppins", sans-serif;
  color: rgb(255, 115, 0);
  font-weight: 300;
  margin-top: 30px;
  font-size: 35px;
}

.team__profile h4 {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1.5px;
  color: #d609f1;
}

.team__profile h5 {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1.5px;
  color: #0edaf5;
}

.team__hero-subtitle {
  margin-top: 20px;
  max-width: 400px;
  text-align: left;
  font-size: 16px;
  line-height: 30px;
  padding-top: 10px;
}

@media only screen and (max-width: 1150px) {
  .team__profiles {
    flex-direction: column;
  }

  .team__profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .team__profile p {
    text-align: center;
    margin: 20px 60px 80px 60px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .team__heading {
    font-size: 40px;
    color: white;
    text-align: center;
  }

  .team__profiles {
    margin: 20px 0;
  }

  .team__profile p {
    margin: 20px 10px 80px 10px;
  }
}

.home__hero-img-wrapper {
  max-width: 555px;
}

.home__hero-img {
  max-width: 95%;
  margin: 0 0 0 100px;
}

/* CSS Styling for Credits Page */

.credits__hero-section {
  padding: 120px;
}

.credits__heading {
  margin-bottom: 24px;
  font-family: "Roboto", serif;
  font-size: 50px;
  line-height: 1.1;
  font-weight: 300;
  color: rgb(255, 115, 0);
  text-align: center;
}

.credits__hero-text-wrapper {
  max-width: 100%;
  color: rgb(255, 115, 0);
  padding-top: 0;
  padding-bottom: 20px;
}

.credits__hero-subtitle {
  color: rgb(245, 241, 241);
  max-width: 440px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 60px;
}

/* Credits End */

img {
  border: 0;
  max-width: 90%;
  vertical-align: middle;
  display: inline-block;
}

/* CSS Styling for BeatBoi Page */

.beatboi-page__hero-section {
  color: black;
}

.beatboi-page__hero-row1 {
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.beatboi-page__hero-text-wrapper {
  max-width: 100%;
  padding-top: 0;
  padding-bottom: 60px;
  align-items: center;
}

.beatboi-page__heading {
  padding: 50px;
  font-family: "Neuton", serif;
  font-size: 50px;
  line-height: 1.1;
  letter-spacing: 0.4rem;
  font-weight: 300;
  color: rgb(255, 115, 0);
  text-align: center;
}

.beatboi-page__hero-img-wrapper {
  max-width: 555px;
}

.beatboi-page__hero-img {
  max-width: 95%;
  display: block;
}

.beatboi-page__hero-imgs {
  max-width: 95%;
  max-height: 400px;
  display: block;
}

.beat_row1 {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
}
.beat_row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.beat_col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1 1;
  max-width: 40%;
  flex-basis: 50%;
}

.beat_col1 {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1 1;
  max-width: 40%;
  flex-basis: 50%;
}
/* Responsive layouts */

/* #1 .container for all components */
@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.footer-container {
  background: rgb(24, 161, 5);
  padding: 1rem 0 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.footer-logo {
  margin-left: 140px;
  align-content: center;
}
.footer-links {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #440aaf;
  transition: 0.3s ease-out;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

